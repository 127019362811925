import React from 'react';
import Linkify from 'react-linkify';

import {
  Alert,
  Button, ButtonGroup,
  Container, Row, Col,
  Card,
  Collapse,
  Table,
  Input
} from 'reactstrap';

import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';

import { MobilePortrait, NotMobilePortrait, LargeDesktop, NotLargeDesktop } from 'utils/responsive.js';

import {EventTitle} from 'common/EventTitle.js';
import {RosterRow} from 'common/RosterRow';
import GolferModal from 'common/GolferModal.js';
import CutCountsModal from 'pages/user/CutCountsModal.js';
import Banner from 'common/Banner.js';
import RenderBannerAds from 'common/RenderBannerAds.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { get_aga_leaderboards, get_bannerAds, add_user_clicks } from 'utils/api.js';
import { get_promo_content } from '../../utils/promoContent.js';

import 'pages/user/Leaderboard.css';

export default class AGALeaderboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      showCuts: false,
      golferModalOpen: false,
      golferModalGolferId: null,
      mcLeaderboard: true,
      friendFocus: false,
      pgaBreakdown: false,
      leaderboardRecPerPage: 0,
      appPageConfig:{},
      mcAdvConfigGrp: null,
      filter: '',
      fundDonateModalOpen: false,
      round: 0,
      dailyLeaderboard: false,
      collapsedAll: true,
    };
    this.fetchLeaderboardsData = this.fetchLeaderboardsData.bind(this);
    this.fetchLeaderboardsDataInterval = this.fetchLeaderboardsDataInterval.bind(this);
    this.toggleFocus = this.toggleFocus.bind(this);
    this.toggleViewCuts = this.toggleViewCuts.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);
    this.renderError = this.renderError.bind(this);
    this.toggleBreakdown = this.toggleBreakdown.bind(this);

    this.fetchLeaderboardsDataPageChange = this.fetchLeaderboardsDataPageChange.bind(this);
    this.fetchLeaderboardsDataChangeRecPerPage = this.fetchLeaderboardsDataChangeRecPerPage.bind(this);

    this.addUserClicks = this.addUserClicks.bind(this);

    this.interval = setInterval(this.fetchLeaderboardsDataInterval, 5*60*1000);

    this.myTeamRef = React.createRef();
    this.myTeamRefMob = React.createRef();
  }

  fetchLeaderboardsDataPageChange(direction){
    let newPageNo = 0;
    if (direction === 'PREV'){
      if (this.state.mcData && this.state.mcData.currPage === 0) return;
      newPageNo = (this.state.mcData.currPage-1);
    }else if (direction === 'NEXT'){
      if (this.state.mcData && this.state.mcData.currPage === this.state.mcData.totalPage-1) return;
      newPageNo = (this.state.mcData.currPage+1);
    }else if (direction === 'LAST'){
      if (this.state.mcData && this.state.mcData.currPage === this.state.mcData.totalPage-1) return;
      newPageNo = (this.state.mcData.totalPage-1);
    }
    this.fetchLeaderboardsData(this.props.leagueGrp.leagueGrpId, this.props.event.eventid, newPageNo);
  }


  fetchLeaderboardsDataChangeRecPerPage(event){
    //alert(event.target.value);
    this.state.leaderboardRecPerPage = parseInt(event.target.value);
    this.fetchLeaderboardsData(this.props.leagueGrp.leagueGrpId, this.props.event.eventid);
  }

  fetchLeaderboardsData(leaguegrpid, eventid, pageNo, filter, round, onSuccess) {
      if (pageNo === undefined) pageNo = 0;
      if (filter === undefined) {
        filter = this.state.filter;
      }
      if (round === undefined) {
        round = this.state.round;
      }
      get_aga_leaderboards(leaguegrpid, eventid, filter, (pageNo||0), this.state.leaderboardRecPerPage, round,
        ({success, eventData, pgaData, mcData, error}) => {
          if (!success) {
            this.setState({loading: false, error});
            return;
          }
          let golfer_counts = {};
          let my_golfers = new Set();
          if (mcData.leaderboard !== undefined){
            for (var team of mcData.leaderboard) {
              if (typeof team.roster === "string") {
                  team.roster = JSON.parse(team.roster);
              }
              if (typeof team.alternates === "string") {
                  team.alternates = JSON.parse(team.alternates);
              }
              for (var golfer of team.roster) {

                if (golfer_counts[golfer] === undefined) {
                  golfer_counts[golfer] = 0;
                }
                golfer_counts[golfer]++

                if (team.usersrow) {
                  my_golfers.add(golfer)
                }
              }
            }
          }

          this.setState({eventData, pgaData, mcData, golfer_counts: mcData.golfer_counts, my_golfers, loading: false });

          if (onSuccess){
            onSuccess();
          }

        },
        (error) => {
          console.log('error getting leaderboard: ' + error);
          this.setState({ loading: false, error: 'The leaderboard failed to load, possibly due to a network problem. Please refresh the page to reload the leaderboard.' });
        }
      )
  }
  fetchLeaderboardsDataInterval() {
    if (this.props.event.status === "closed") {
      return
    }

    this.fetchLeaderboardsData(this.props.leagueGrp.leagueGrpId, this.props.event.eventid, this.state.mcData.currPage);
  }
  componentDidMount() {

      let x = this.props.selectedLeague && this.props.selectedLeague.userPrefs ? this.props.selectedLeague.userPrefs['LEADERBOARD_FILTER']: 'OFF';
      let filter = (x === 'ON') ?'FRIENDS':'';
      this.setState({friendFocus: (filter==='FRIENDS'?true:false)});

      console.log( 'x :: ' + x);

      this.fetchLeaderboardsData(this.props.leagueGrp.leagueGrpId, this.props.event.eventid,0,filter);
      get_promo_content().then(({events, announcement}) => {
        this.setState({promocontent: events, announcement});
      });


      let lb_banner_config_grp = 'RENDER_ALL_BANNER';
      let mcLeaderboardAdv_config = 'RENDER_ALL_BANNER';


      get_bannerAds(this.state.eventData?this.state.eventData.leagueGrpid:null, null ,lb_banner_config_grp,'',
      ({appPageConfig}) => {
        this.setState({
          appPageConfig,
        });
      }, (error) => {
        console.log(error);
      });

      this.setState({
        mcAdvConfigGrp:mcLeaderboardAdv_config,
      });



  }
  componentWillReceiveProps(nextProps) {
      if (nextProps.event.eventid !== this.props.event.eventid) {
        let x = this.props.selectedLeague && this.props.selectedLeague.userPrefs ? this.props.selectedLeague.userPrefs['LEADERBOARD_FILTER']: 'OFF';
        let filter = (x === 'ON') ?'FRIENDS':'';
        this.setState({friendFocus: (filter==='FRIENDS'?true:false)});
        console.log( 'x :: ' + x);

        this.fetchLeaderboardsData(nextProps.leagueGrp.leagueGrpId, nextProps.event.eventid, 0,filter)
      }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  toggleFocus() {
    let filter = (!this.state.friendFocus?'FRIENDS':'');
    let x = this.props.selectedLeague.userPrefs['LEADERBOARD_FILTER'];
    console.log('LEADERBOARD_FILTER :: ' + x);
    if (this.props.selectedLeague && this.props.selectedLeague.whiteLabel && filter === 'FRIENDS'){
      this.setState({leaderboardRecPerPage: 3000 }); // for whitelabel filter pagination should be off
    }else if (this.props.selectedLeague && this.props.selectedLeague.whiteLabel && filter === ''){
      this.setState({leaderboardRecPerPage: 50 });
    }
    this.props.selectedLeague.userPrefs['LEADERBOARD_FILTER'] = (filter === 'FRIENDS'?'ON':'OFF');

    this.setState({ friendFocus: !this.state.friendFocus, filter });
    this.fetchLeaderboardsData(this.leagueGrp.leagueGrpId, this.props.event.eventid,0,filter);
  }
  toggleViewCuts() {
    this.setState({ showCuts: !this.state.showCuts })
  }
  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }
  toggleBreakdown() {
    this.setState({pgaBreakdown: !this.state.pgaBreakdown});
  }
  showGolferDetails(golferid) {
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  addUserClicks() {
    let appPageConfig = this.state.appPageConfig;
    window.open(appPageConfig['BANNER_CLICK_URL'], "_blank")
    add_user_clicks(this.props.activeLeague, appPageConfig['BANNER_DSKTP_IMG_URL'], appPageConfig['BANNER_CLICK_URL'],
    ({}) => {

    }, (error) => {
      console.log(error);
    });

  }

  executeScrollToMyTeam = () => {
    if (this.state.mcData.user_best_entry_indx > -1){
      let tPage = this.state.mcData.totalPage === 1 ? 0 : Math.floor(this.state.mcData.user_best_entry_indx/(this.state.leaderboardRecPerPage===0?50:this.state.leaderboardRecPerPage));
      if (this.state.mcData.currPage === tPage){
        this.myTeamRef.current.scrollIntoView({block: "center", inline:"nearest"});
      }else{
        this.fetchLeaderboardsData(this.leagueGrp.leagueGrpId, this.props.event.eventid,tPage,'',this.state.round, ()=>{this.myTeamRef.current.scrollIntoView({block: "center", inline:"nearest"});});
      }
    }

  }

  depositFundSuccess = (userLedgerId) =>{
    this.setState({fundDonateModalOpen: false});
    confirmAlert({
      title: 'Thank You',
      message: 'Thank you for your donation. Good luck!',
      buttons: [
        {
          label: 'OK',
          onClick: () => { }
        }
      ]
    });
  }

  toggleFundDonate = () => {
    this.setState({fundDonateModalOpen: !this.state.fundDonateModalOpen});
  }

  handlePrePaymentSteps = (onSuccess) =>{
    onSuccess();
  }

  handleChange = (event) => {
    let val = event.target.value;
    this.setState({[event.target.name]: val});
    if(event.target.name === 'round'){
      this.setState({[event.target.name]: parseInt(val)});
      this.fetchLeaderboardsData(this.leagueGrp.leagueGrpId, this.props.event.eventid,0,'',val);
    }
  }

  renderError() {
    //const prizePayout =
    const { mcData = {} } = this.state;
    const { perfectteam } = mcData;
    return (
      <Container className="leaderboardContainer">
        <EventTitle {...this.props} perfectteam={perfectteam} className="px-3 px-lg-4"/>
        <h4 className="px-3 pt-5 px-lg-4" style={{fontWeight: "200", letterSpacing: "0.7px"}}>{this.state.error}</h4>
      </Container>
    )
  }

  toggleCollapseAll =()=>{
    this.setState({collapsedAll: !this.state.collapsedAll})
  }
  render() {
    if (this.state.loading) {
      return null;
    }
    if (this.state.error) {
      return this.renderError();
    }
    console.log('this.props.selectedLeague');
    console.log(this.props.selectedLeague);
    const maybeLinkWrapper = (link, element) => link
      ? <a href={link} target='_blank' rel="noopener noreferrer">{element}</a>
      : element;


    const eventData = this.state.eventData;
    eventData['srcLeague'] = this.props.srcLeague;
    eventData['srcLeaderboard'] = 'AGA';
    return (
      <Container className="leaderboardContainer">

        <EventTitle {...this.props} perfectteam={this.state.mcData.perfectteam} className="px-3 px-lg-4"
          prizePayout={this.state.mcData.prizepayout}
          toggleFundDonate={this.toggleFundDonate}
          eventData={eventData}
          suppressEventRule={true}/>

        <Row noGutters className="py-2 mx-lg-2 d-none d-xl-flex justify-content-center">
          <Col className="mb-0 px-lg-3" style={{maxWidth: 750}}>
              <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueGrpId={eventData.leagueGrpid} leagueId={''} place={'LEADERBOARD_HEADER'}/>
          </Col>
        </Row>
        <Row noGutters className="pt-2 mx-lg-2 d-flex justify-content-center d-xl-none">
            {this.state.mcLeaderboard ?
            <Col className="mb-0 px-lg-3 d-flex justify-content-start px-3" style={{maxWidth: 768}}>
              <div style={{maxWidth: 768}}>
                <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueGrpId={eventData.leagueGrpid} leagueId={''} place={'LEADERBOARD_HEADER'}/>
              </div>
            </Col>
            : undefined
            }
        </Row>

        {/*
        <Row noGutters className="pt-4 d-flex justify-content-center d-xl-none">
          <ButtonGroup>
            <Button style={{minWidth: 200}} color="success" active={this.state.mcLeaderboard} onClick={() => this.setState({mcLeaderboard: true})}>
              Majors Challenge
            </Button>
            <Button style={{minWidth: 200}} color="success" active={!this.state.mcLeaderboard} onClick={() => this.setState({mcLeaderboard: false})}>
              PGA
            </Button>
          </ButtonGroup>
        </Row>
        */}

        <NotLargeDesktop>
        <Row noGutters className="pt-2 mx-lg-2 d-flex justify-content-center">
          {this.state.mcLeaderboard ?
            <Col className="mb-5 px-lg-3 d-flex justify-content-center">
              {this.props.clubstatus === 'expired'?
                <div>
                    <h6 className="text-center mx-4">Subscription Expired. Contact Your League Commissioner.</h6>
                </div>:
                <McLeaderboard leagueId={this.props.activeLeague}
                selectedLeague={this.props.selectedLeague}
                leagueName={this.props.leagueName}
                fetchLeaderboardsDataPageChange={this.fetchLeaderboardsDataPageChange}
                fetchLeaderboardsDataChangeRecPerPage={this.fetchLeaderboardsDataChangeRecPerPage}
                addUserClicks={this.addUserClicks}
                toggleFocus={this.toggleFocus}
                myTeamRef={this.myTeamRef}
                executeScrollToMyTeam={this.executeScrollToMyTeam}
                toggleFundDonate={this.toggleFundDonate}
                handleChange={this.handleChange}
                toggleCollapseAll={this.toggleCollapseAll}
                {...this.state} />
              }
            </Col> :
            <Col xs="auto" className="d-flex justify-content-center">
              <PgaLeaderboard {...this.state} toggleViewCuts={this.toggleViewCuts}
                showGolferDetails={this.showGolferDetails} toggleBreakdown={this.toggleBreakdown}/>
            </Col>
          }
        </Row>
        </NotLargeDesktop>
        {/*Desktop*/}
        <LargeDesktop>
        <Row noGutters className="pt-2 mx-lg-2 justify-content-center">
          <Col className="mb-5 px-lg-3">
            {this.props.clubstatus === 'expired'?
              <div>
                  <h6 className="text-center mx-4">Subscription Expired. Contact Your League Commissioner.</h6>
              </div>:
              <McLeaderboard leagueId={this.props.activeLeague}
              selectedLeague={this.props.selectedLeague}
              leagueName={this.props.leagueName}
              fetchLeaderboardsDataPageChange={this.fetchLeaderboardsDataPageChange}
              fetchLeaderboardsDataChangeRecPerPage={this.fetchLeaderboardsDataChangeRecPerPage}
              addUserClicks={this.addUserClicks}
              toggleFocus={this.toggleFocus}
              myTeamRef={this.myTeamRef}
              executeScrollToMyTeam={this.executeScrollToMyTeam}
              toggleFundDonate={this.toggleFundDonate}
              handleChange={this.handleChange}
              toggleCollapseAll={this.toggleCollapseAll}
              {...this.state} />
            }
          </Col>
          <Col xs="auto">
            <PgaLeaderboard {...this.state} toggleViewCuts={this.toggleViewCuts} showGolferDetails={this.showGolferDetails} toggleBreakdown={this.toggleBreakdown} />
          </Col>
        </Row>
        </LargeDesktop>

        <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>




      </Container>
    );
  }
}

const McLeaderboard = (props) => {



  return (
    <Card body className="p-0" style={{maxWidth: 768, border:'0px'}}>
      <Row className="d-flex mx-0 py-3 justify-content-between light-border-lr light-border-t">
        <div className="d-flex ">
          <div className="mcLeaderboardHeaderTitle">
            {props.eventData.leagueGrpName} Top 100
          </div>
        </div>
      </Row>

      <TeamLeaderboard {...props} />
    </Card>
  )
}

const TeamLeaderboard = (props) => {
  let showTeam = true;
  const whiteLabel = props.selectedLeague && props.selectedLeague.whiteLabel;
  if (props.selectedLeague && props.selectedLeague.whiteLabel && !props.selectedLeague.whiteLabel.use_team_names){
    showTeam = false;
  }

  let leaderboardRow = props.mcData.leaderboard.filter(item =>
    !(props.friendFocus && !(item.winner || item.friend || item.usersrow))
  );

  const membersSection = props.leagueId
    ? <a href={`/members/${props.leagueId}`}>Members section</a>
    : 'Members section';
  const maybeAddFriends = props.friendFocus &&
    <div className="small muted py-1 pl-4" style={{textAlign:'center', backgroundColor:'#ECF0F180'}}>Choose the friends you want to follow in the {membersSection}.</div>

  const gapHeight = props.friendFocus ? '25px' : '50px';
  const bestTotal = props.mcData.leaderboard.length > 0 ? Math.min(...props.mcData.leaderboard.map(item => item.total)):0;
  const leaderboard = props.mcData.leaderboard.map((item, index) =>
      props.friendFocus && !(item.winner || item.friend || item.usersrow || item.host) ?
      undefined :
      <>
      {(index === (props.mcData.frndTeamCount + props.mcData.hostTeamCount))  &&
        <tr className=""><td colSpan="10" className="p-0">{maybeAddFriends}</td></tr>

      }
      {((props.mcData.frndTeamCount>0 || props.mcData.hostTeamCount>0) && index === (props.mcData.frndTeamCount + props.mcData.hostTeamCount))  &&

        <tr><td colSpan="10" className="p-0" style={{height:gapHeight, backgroundColor:'#ECF0F180', borderTop:'0px'}}>{' '}</td></tr>

      }

      <TeamLeaderboardRow key={item.entryId} {...item} rowIndex={index} totalRow={leaderboardRow.length} bestTotal={bestTotal}
        appPageConfig={props.appPageConfig}
        addUserClicks={props.addUserClicks} leagueId={props.leagueId}
        pgaData={props.pgaData} eventData={props.eventData}
        mcData={props.mcData} myTeamRef={props.myTeamRef}
        selectedLeague={props.selectedLeague}
        round={props.round}
        collapsedAll={props.collapsedAll}/>
      </>
  );


  let leaderboardPagerItems = [50,100,200,500,1000,2000];
  const leaderboardPagerRecPerPage = leaderboardPagerItems.map((item) =>
      <option value={item}>{item}</option>
  );

  const leaderboardPager = (props.mcData.totalPage < 2)
    ? ''
    : <div className="small muted light-border-bt light-border-lr">
        <Table responsive style={{marginBottom:0}}  className="mcLeaderboardTable">
          <thead>
            <tr>
              <th className="text-center"></th>
              <th className="text-center text-nowrap">
                <span className="text-center border-right  border-dark pl-2 pr-2 paginationBtn" onClick={()=>{ props.fetchLeaderboardsDataPageChange('FIRST') }}>First</span>
                <span className="text-center border-right border-dark pl-2 pr-2 paginationBtn" onClick={()=>{ props.fetchLeaderboardsDataPageChange('PREV') }}> Prev</span>
                <span className="text-center pl-2 pr-1">Records Per Page: </span>
                <select value={props.leaderboardRecPerPage} onChange={(x)=>{ props.fetchLeaderboardsDataChangeRecPerPage(x) }}>
                  {leaderboardPagerRecPerPage}
                </select>
                <span className="text-center border-right border-dark pl-2 pr-2 paginationBtn" onClick={()=>{ props.fetchLeaderboardsDataPageChange('NEXT') }}>Next</span>
                <span className="text-center pl-2 pr-2 paginationBtn" style={{paddingLeft:10}} onClick={()=>{ props.fetchLeaderboardsDataPageChange('LAST') }}>Last</span>
              </th>
              <th className="text-center text-nowrap">{props.mcData.pagerTxt}</th>

            </tr>
          </thead>
        </Table>
      </div>



  return (
    <div className="light-border-tb">

      <Table responsive style={{marginBottom:0}}  className="mcLeaderboardTable ">
        <thead className="light-border-lr">
          <tr>
            <th className="text-center p-0" style={{width:props.mcData.hostTeamCount>0?'8px':'0px'}}></th>
            <th className="text-center">POS</th>
            <th>{showTeam?'Team (Owner)':'Team Owner'}</th>
            <th className="text-center">Total</th>
            <th className="text-center">Cuts</th>
            { props.eventData.status !== "closed" &&
              <th className="text-center">Left</th>
            }
            <th className="text-center">Behind</th>

            {props.mcData.leaderboard.length < 51 &&
            <th onClick={props.toggleCollapseAll}><FontAwesome size="lg" name={props.collapsedAll ? "angle-down" : "angle-up"} /></th>
            }
            {props.mcData.leaderboard.length > 50 &&
            <th></th>
            }
          </tr>
        </thead>
        {leaderboard}
      </Table>
      {leaderboardPager}

    </div>
  );
}

class TeamLeaderboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapsed: this.props.collapsedAll};
  }

  componentWillReceiveProps(nextProps) {
      if (nextProps.collapsedAll !== this.props.collapsedAll) {
        this.setState({ collapsed: nextProps.collapsedAll  });
      }
  }

  toggle() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {

    const leaderboardBanner = ((this.props.rowIndex === 3 || (this.props.totalRow < 5  && this.props.totalRow === this.props.rowIndex+1)))?
        <tr className='light-border-lr'>
          <td colSpan="12" style={{padding: 0, borderTop:"none"}}>
            <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueGrpId={this.props.eventData.leagueGrpid} leagueId={this.props.leagueId} place={'LEADERBOARD'}/>
          </td>
        </tr>
      : undefined
      ;

    const rank = (rank, winner) => {
      let classname = "mcLeaderboardTeamRowRank";

      if (winner) {
        classname += " winner"
      }

      return <span className={classname}>
        {rank}
      </span>
    }

    const moved = (m) => {
      return  m === 0 ? <span style={{color: "#ccc"}}>-</span> :
                m > 0 ? <span style={{color: "#27ae60"}}><FontAwesome name="arrow-up" /> {m}</span> :
                  <span style={{color: "#e74c3c"}}><FontAwesome name="arrow-down" /> {-m}</span>
    }

    const total = (score) => {
      const color = (score !== null && score < 0) ? "#e74c3c" : "#515356";
      const content = score === 0 ? "E" :
              score === null ?
                "-" : score > 0 ?
                    "+"+score : score;
      return <span style={{color: color}}> {content} </span>
    }

    const winning = (w) => {
      const recolor = w > 0 ? {color: "#27ae60"} : {};
      return  <span style={recolor}>{ numeral(w).format('0,0[.]00') }</span>
    }

    const roster = (roster, alternates, pgaData) => {
      return roster.map((item, index) => {
        return {
          name: pgaData[item].player,
          score: (this.props.round === 0 ? pgaData[item].topar : pgaData[item]['r'+this.props.round + '_topar']),
          status: (this.props.round === 0 ? pgaData[item].status : pgaData[item]['r'+this.props.round + '_status']),
          thru: (this.props.round === 0 ? pgaData[item].thru : pgaData[item]['r'+this.props.round+'_thru']),
          alternate: alternates.indexOf(item) !== -1,
          tier:pgaData[item].tier,
        }
      })
    }

    let bodyrowclass = "mcLeaderboardTeamRowBody";
    if (!this.state.collapsed) {
      bodyrowclass += " expanded";
    }
    if (this.props.host) {
      bodyrowclass += " host";
    }else if (this.props.usersrow) {
      bodyrowclass += " personal";
    } else if (this.props.friend) {
      bodyrowclass += " friend";
    }

    const cup = (this.props.cup && this.props.eventData.cupAllowNewTeams && this.props.eventData.cupAllowNewTeams !== 'All Season Long') && <FontAwesome name="trophy" />
    const exclueFromPool = this.props.inPool === false && <FontAwesome name="asterisk" size="xs" />

    const myRef = (this.props.mcData.user_best_entry_place === this.props.rank && this.props.usersrow)?this.props.myTeamRef:null;
    let showTeam = true;
    if (this.props.selectedLeague && this.props.selectedLeague.whiteLabel && !this.props.selectedLeague.whiteLabel.use_team_names){
      showTeam = false;
    }

    return (
      <tbody className="light-border-lr">
        <tr onClick={this.toggle} className={bodyrowclass} ref={myRef}>
          {this.props.host &&
          <td className="vertical-text-td p-0 align-middle text-center">
            <div className="vertical-text pt-2">

            </div>
          </td>
          }
          {!this.props.host &&
          <td className="align-middle text-center p-0">

          </td>
          }

          <td className="align-middle text-center">{rank(this.props.rank, this.props.winner)}</td>
          <td className="mcLeaderboardTeamRowBase py-1">
            <div>{this.props.owner}</div>
            <div style={{color: "gray", fontSize: ".8em"}}>{this.props.league_name}</div>
          </td>
          <td className="mcLeaderboardTeamRowBase text-center">{total(this.props.total)}</td>
          <td className="mcLeaderboardTeamRowBase text-center">{this.props.cuts}</td>
          { this.props.eventData.status !== "closed" &&
            <td className="mcLeaderboardTeamRowBase text-center">{this.props.left}</td>
          }
          <td className="mcLeaderboardTeamRowBase text-center">{this.props.total-this.props.bestTotal}</td>

          <td className="align-middle"><FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} /></td>
        </tr>
        { !this.state.collapsed  &&
          <tr className={bodyrowclass}>
            <td colSpan="12" style={{padding: 0, borderTop:"none"}}>
              <Collapse isOpen={!this.state.collapsed}>
                <RosterRow roster={roster(this.props.roster, this.props.alternates, this.props.pgaData)} />
              </Collapse>
            </td>
          </tr>
        }
        {leaderboardBanner}
      </tbody>
    )
  }
}

const PgaLeaderboard = (props) => {
  return <div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="pgaLeaderboardTitle"> PGA Leaderboard </div>
          </div>
          <PgaNormalLeaderboard {...props} />
        </div>
}



const roundScore = (score) => {
  const color = (score !== null && score < 0) ? "#e74c3c" : "#515356";
  const content = score === 0 ? "E" :
          score === null ?
            "-" : score > 0 ?
                "+"+score : score;
  return <span style={{color: color}}> {content} </span>
}

const PgaNormalLeaderboard = (props) => {

  //props.eventData.status = "round2";
  //props.eventData.projected_cut = -2;

  const thru = (t) => {
    return t === 18 ?
      <span style={{color: "#aaa"}}>F</span> :
      (t === 0 ? "-" : t)
  }

  const pgaRowData = () => {
    var rowData = []

    for (var player in props.pgaData) {
      if (props.showCuts || props.pgaData[player].status === 'active') {
        rowData.push(props.pgaData[player]);
      }
    }

    rowData.sort((a, b)=>{
        if (a.place === null || b.place === null) {
          if (a.place !== null) { return -1; }
          if (b.place !== null) { return 1; }
          return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
        }

        let a_place = a.place[0]==='T' ? a.place.substr(1) : a.place;
        let b_place = b.place[0]==='T' ? b.place.substr(1) : b.place;

        if (a_place === b_place) {
          return a.player.toUpperCase() < b.player.toUpperCase() ? -1 : 1;
        }

        return a_place - b_place;
    })

    return rowData
  }


  const showR1Score = props.eventData.status === "closed" || props.eventData.status === "round1";
  const showR2Score = props.eventData.status === "closed" || props.eventData.status === "round2";
  const showR3Score = props.eventData.status === "closed" || props.eventData.status === "round3";
  const showR4Score = props.eventData.status === "closed" || props.eventData.status === "round4";

  let projected_cut_visible = 0;

  const pgaRows = pgaRowData().map((item, index) => {


    if (projected_cut_visible === 1) {
      projected_cut_visible = -1;
    }
    //console.log(props.eventData.status + ' ## ' + projected_cut_visible + '  --  ' + item.total + ' == ' + props.eventData.projected_cut);
    //console.log(item.total > props.eventData.projected_cut);
    //console.log(parseInt(item.total) > parseInt(props.eventData.projected_cut));
    if (props.eventData.status === "round2" && projected_cut_visible === 0 && item.total > props.eventData.projected_cut){
      projected_cut_visible = 1;
    }

    let classname = "pgaLeaderboardRow";
    if (props.my_golfers.has(item.id)) {
      classname += " personal";
    }


    if (item.status === 'WD*') {
        return <tr key={index} className={classname}>
          <td className="text-center">{item.status}</td>
          <td className="pgaLeaderboardPlayer font-weight-bold">
            <span onClick={() => props.showGolferDetails(item.id)}>
              {item.player}
            </span>
          </td>
          { showR1Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          { showR2Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          { showR3Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          { showR4Score &&
            <td className="text-center font-weight-light">{ roundScore(null) }</td>
          }
          <td className="text-center font-weight-bold">{roundScore(null)}</td>
          { props.eventData.status !== "closed" &&
            <td className="text-center">{thru(0)}</td>
          }
        </tr>
    }

    let projectedCut = (props.eventData.projected_cut === 0 ? 'E' :
                          (props.eventData.projected_cut>0?'+'+props.eventData.projected_cut:
                          '-'+props.eventData.projected_cut)
                       );

    console.log(projected_cut_visible + '  --  ' + projectedCut);
    return (
      <>
      { projected_cut_visible === 1 &&
        <tr key={index + projected_cut_visible} className={classname}>
          <td colSpan="6" style={{backgroundColor:"lightgrey",paddingLeft:"15px", textAlign:"center"}}>Projected Cut {projectedCut}</td>
        </tr>
      }
      <tr key={index} className={classname}>
        <td className="text-center">{item.status === 'active' ? item.place : item.status.toUpperCase()}</td>
        <td className="pgaLeaderboardPlayer font-weight-bold">
          <span onClick={() => props.showGolferDetails(item.id)}>
            {item.player}
          </span>
        </td>
        { showR1Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round1' ? null : item.r1) }</td>
        }
        { showR2Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round2' ? null : item.r2) }</td>
        }
        { showR3Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round3' ? null : item.r3) }</td>
        }
        { showR4Score &&
          <td className="text-center font-weight-light">{ roundScore(item.thru === 0 && props.eventData.status === 'round4' ? null : item.r4) }</td>
        }
        <td className="text-center font-weight-bold">{roundScore(item.total)}</td>
        { props.eventData.status !== "closed" &&
          <td className="text-center">{thru(item.thru)}</td>
        }
      </tr>
    </>
    )


    }
  );

  return (
    <div>
      <Table size="sm" className="pgaLeaderboardTable">
        <thead>
          <tr style={{color: "gray", fontSize: "0.65rem", textTransform: "uppercase",}}>
            <th className="text-center">Place</th>
            <th>Player</th>
            { showR1Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R1" : "Today" }</th>
            }
            { showR2Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R2" : "Today" }</th>
            }
            { showR3Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R3" : "Today" }</th>
            }
            { showR4Score &&
              <th className="text-center">{ props.eventData.status === "closed" ? "R4" : "Today" }</th>
            }
            <th className="text-center">Total</th>
            { props.eventData.status !== "closed" &&
              <th className="text-center">Thru</th>
            }
          </tr>
        </thead>
        <tbody>
          {pgaRows}
        </tbody>
      </Table>
      <div style={{color: "blue"}} className="ml-2 mb-1" onClick={() => {props.toggleViewCuts()}}>
        {props.showCuts ?
          "Hide Cut Golfers" :
          "Show Cut Golfers"
        }
      </div>
    </div>
  )
}


const PromoAnnouncement = ({announcement}) => {
  if (!announcement) {
    return null;
  }
  return (
      <Row noGutters className="d-flex justify-content-center">
        <Col lg="7">
          <Alert color="success" className="my-4">
            {announcement.title && <h6>{announcement.title}</h6>}
            <Linkify properties={{target: '_blank'}}>
              <span style={{whiteSpace: "pre-line", fontSize: "14px"}}>{announcement.message}</span>
            </Linkify>
          </Alert>
        </Col>
      </Row>
    )
}
